/* global styling */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Sen:wght@400..800&display=swap');


img.shadow {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);

}

video{
  width: 100%;
}

p{
  margin-bottom: 30px;
}

h1{
  font-family: "Sen", sans-serif;
  margin-left: 70px;
  line-height: 1.125em;


}

h2{
  font-family:"Sen", sans-serif;
  font-size: 1.125em;
  margin-top: 4.375em;
  color: #1A51DE;
  margin-bottom: 0px;

}

h2 + h3{
  font-family:"Sen", sans-serif;
  font-size: 1.75em;
  line-height: 1em;
  margin:  0.625em 0;
  /* margin-top: 0.625em; */

}

h3{
  font-family:"Sen", sans-serif;
  font-size: 1.75em;
  line-height: 1em;
  /* margin:  0.625em 0; */
  margin-top: 3em;
  margin-bottom: 0.625em;
}

h4{
  font-family:"Sen", sans-serif;
  font-size: 1.5em;
  line-height: 1.125em;
  margin:  0.625em 0;
  /* margin-top: 0.625em; */

}

h5{
  font-family:"Sen", sans-serif;
  font-size: 1.25em;
  color:#1A51DE;
  margin-bottom: 0; 
}

.numbered-list {
  margin-top: 3em;
  margin-bottom: 3em;
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
}
.numbered-list li {
  counter-increment: list-counter;
  margin-top: 2.5em;
  display: flex;
  align-items: center;
}
.numbered-list li::before {
  content: counter(list-counter) ". ";
  content: counter(list-counter);
  font-size: 40px;
  font-weight: bold;
  font-family:"Sen", sans-serif;
  color: #1A51DE;
  margin-right: 30px;
}
.numbered-list li p {
  margin: 0;
}

body{
  font-family: 'Lato', sans-serif;
  line-height: 1.5em;
  font-size: 17px;
  /* background-color: #FDF7F3; */
  background-color: white;
}


.half{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.half .item{
  max-width: 45%;
}

iframe{
  aspect-ratio: 16 / 9;
}

.third{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.third .item{
  width: 30%;
}

.quarter{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quarter .item{
  max-width: 22%;
}

.container{
  display: flex;
  justify-content: space-between;
  object-fit: contain;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 3em;
  /* align-items: center; */
}

.description{
  width: 30%;
}

.one-phone .description{
  width: 35%;
}

.screens{
  display: flex;
  width: 65%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

}

.screens img{
  object-fit: contain;
  margin-bottom: 10px;
}

.screens img.phone{
  width: 40%;
  /* box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15); */

}

@media only screen and (max-width: 768px){

  body{
    font-size: 15px;
  }

  .half .item{
    max-width: 100%;
  }

  .quarter .item{
    max-width: 45%;
  }

  .third .item{
    width: 100%;
  }

  .container .description{
    width: 100%;
  }

  .screens{
    width: 100%;
  }

  .container.reverse{
    flex-direction: column-reverse;
  }

  h2{
    font-size: 1em;
  }

  h2 + h3{
    font-size: 1.3em;
  }

  h3{
    font-size: 1.3em;
  }

  h4{
    font-size: 1.2em;
  }

  h5{
    font-size: 1.2em;
  }
  

}

/* navbar styling */
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5%;
  font-size: 16px;
  /* background-color: #FDF7F3; */
  background-color: white;
  border-bottom: 1px solid rgb(0, 0, 0);
  font-family: 'Sen', sans-serif;
  z-index: 1;
}

.navbar a {
  text-decoration: none;
  color: black;
}

.navbar .navlinks-container a.active {
  color: #1A51DE !important; 
  text-decoration:none !important;
  font-weight: 600;
}

.navbar .navlinks-container a:hover {
  text-decoration: none;
  color: black;
  text-decoration: underline;
}


.navlink-left {
  margin-right: 20px;
}

.navlink-right {
  margin-left: 20px;
}

@media only screen and (max-width: 768px){
  /* .navbar{
    font-size: 15px;
    /* padding: 5%; */
  

  .navlink-left {
    margin-right: 20px;
  }
  
  .navlink-right {
    margin-left: 20px;
  }
  
  
}


/* intro styling */

.intro{
  margin: 5% 20%;
  font-family: 'Sen', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color:black;
}


.intro-pic img{
  width: auto;
  height: 250px;
}

.intro-pic{
  /* padding-left: 5%; */

}

.intro-words{
  width: 60%;
  line-height: 2em;

}

.intro .top {
    font-size: 2.2em;
    font-weight: 700;
}

.intro .bottom {
  margin-top: 1em;
  font-size: 22px;
  color:black;

}

@media only screen and (max-width: 768px){
  .intro{
    margin: 5%;

  }

  .intro .bottom{
    font-size: 18px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px){
  .intro{
    /* margin: 10% 5%; */
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }

  .intro .top{
    font-size: 25px;
  }

  .intro .bottom{
    font-size: 13px;
    line-height: 18px;
  }

  .intro-pic img{
    max-width: 150px;
    height: auto;
  }
  .intro-pic{
    padding: 5% 0%;
  }

  .intro-words{
    width: 55%;
  }
}

/* projects styling */
.projects-container {
  /* max-width: 1200px; */
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  justify-items: center;
  justify-content: center; */
}

/* project card styling */

.project-card-container{
  display: flex;
  width: 100%;
  /* white-space: pre-wrap;
  word-break: break-word; */
  padding-bottom: 80px;
  align-items: center;
}

.project-title {
  /* padding-top: 10px;
  padding-bottom: 10px; */
  font-size: 16px;
  font-family: "Sen", sans-serif;
}

.project-card-info{
  padding-left: 40px;
  width: 35%;
}

.project-card-desc{
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 500;
  font-family: "Sen", sans-serif;
  padding: 20px 0;

}

.project-card-desc a{
    text-decoration: none;
    color: black;  
}

.project-card-desc a:hover{
  color: #1A51DE;
}

.project-skills{
  font-family: "Sen", sans-serif;
  color:rgb(84, 84, 84);
  padding-top: 20px;
  font-size: 13px;
  letter-spacing: 0.08em;
  line-height: 16px;
}

.project-img{
  padding:0;
  width: 65%;
  overflow: hidden;
}

a > img:only-child {
  display: block;
}

.project-img img{
  width: 100%;
  transition: transform .3s ease;

}

.project-img img:hover{
  transform: scale(1.05);
}

@media only screen and (max-width: 1100px){
  .project-title{
    font-size: 14px;
  }

  .project-card-desc{
    font-size: 22px;
    padding: 10px 0;
  }
}

@media only screen and (max-width: 1000px){
  .project-title{
    font-size: 13px;
  }

  .project-card-desc{
    font-size: 20px;
    padding: 5px 0;
  }

  .project-skills{
    letter-spacing: 0.02em;

  }
}

@media only screen and (max-width: 768px){
  .projects-container{
    margin-left: 5%;
    margin-right: 5%;
  }

  .project-card-info{
    padding-left: 20px;
  }

  .project-title{
    font-size: 12px;
  }

  .project-card-desc{
    padding: 5px 0;
    font-size: 18px;
  }

  .project-skills{
    font-size: 12px;
    padding-top: 8px;
  }


}

@media only screen and (max-width: 600px){
  .project-img{
    width: 100%;
  }

  .project-card-container{
    max-width: 100%;
    flex-wrap: wrap;
  }
  /* .projects-container{
    margin-left: 5%;
    margin-right: 5%;
  } */

  .project-card-info{
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }

  .project-title{
    font-size: 15px;
  }

  .project-card-desc{
    padding: 5px 0;
    font-size: 21px;
  }

  .project-skills{
    /* padding: 0;
    font-size: 10px; */
  }
}

/* project overview styling */

.project-overview-container h1{
  margin-left: 0px;
}

.project-summary{
  display: flex;
  /* max-width: 1000px; */
  justify-content: space-between;
  flex-wrap: wrap;
}

.project-desc{
  max-width: 60%;
  padding-right: 20px;
}

.project-info{
  max-width: 30%;
}

.project-overview-pic video{
  object-fit: contain;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.project-overview-pic video:focus { outline:none; }


@media only screen and (max-width: 768px){
  .project-desc{
    max-width: 100%;
    margin-bottom: 10%;
  }
  .project-info{
    max-width: 100%;
  }
  
}


/* project page styling */
.project-page{
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 20px;
}

.project-page img{
  width: 100%;
  /* object-fit: contain; */
}

@media only screen and (max-width: 768px){
  .project-page{
    margin-left: 5%;
    margin-right: 5%;
  }
  }


/* maobi specific */
 img.design-sys{
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  margin-top: 1em;
  margin-bottom: 2em;
}

.flow-lofi-images{
  display: flex;           
  flex-direction: row;
  object-fit: contain;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}

.flow-lofi-images img{
  margin: 10px; 
  width: 45%;
  height: auto;
}

@media only screen and (max-width: 768px){
  .flow-lofi-images img{
    width: 100%;
    height: auto;
  }
}

/* up next styling */
.nextproject-container{
  margin-top: 100px;;
  text-align: right;
  margin-right: 5%;
  font-family: "Sen", sans-serif;
  font-size: 20px;


}

.nextproject-container a{
  text-decoration: none;
  color: black;
}

.nextproject-container a:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 768px){
  .nextproject-container{
    margin-top: 100px;;
    text-align: center;
    font-size: 15px;

  }
  
}


/* market2u specific */
.persona{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5%;
  align-items: center;
  justify-content: center;
}

.persona img{
  width: 15%;
  border-radius: 10px;
}

.persona p{
  width: 75%;
  padding-left: 3%;
}

.flow-images{
  display: flex;           
  flex-direction: row;
  object-fit: contain;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.flow-images img{
  margin: 5px; 
  width: 30%;  
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
       
}

.flow-images img.phone{
  margin: 5px; 
  width: 15%;  
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
       
}

.final-prototypes .row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
}

.final-prototypes .row p{
  width: 25%;
  padding-right: 20px;
}

.final-screens{
  display: flex;
  object-fit: cover;
  flex-wrap: wrap;

}

.final-prototypes .desktop {
  max-width: 30em;
  padding-bottom: 0.5em;
}

.final-prototypes .phone {
  max-width: 9em;
  padding-bottom: 0.5em;
}

@media only screen and (max-width: 768px){
  .persona img{
    width: 100%;
  }

  .persona p{
    width: 100%;
  }

  .final-prototypes .row p{
    width: 100%;
    padding-right: 0px;
  }
  

  .flow-images img{
    width: 100%;
  }

  .flow-images img.phone{
    width: 100%;
  }

  .final-prototypes .desktop {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .final-prototypes .phone {
    max-width: 100%;
    width: 100%;
  }
}



/* vagary specific */
.synthesis, .crazy8, .storyboards {
  display: flex;
  flex-direction: row;
  object-fit: contain;
  flex-wrap: wrap;
  justify-content: center;
}

.synthesis img{
  width: 50%;
}

.crazy8 img{
  width: 50%;
}

.storyboards img{
  width: 50%;
}

.vagary-final .flow-images {
  justify-content: center;
}

.vagary-final .flow-images img.phone{
  width: 30%;
  box-shadow: none;
}

@media only screen and (max-width: 768px){
  .synthesis img{
    width: 100%;
  }
  
  .crazy8 img{
    width: 100%;
  }
  
  .storyboards img{
    width: 100%;
  }
  .vagary-final .flow-images img.phone{
    width: 100%;
  }
}


/* play styling */
.play-container{
  /* margin-left: 70px; */
  padding: 5%;
}

.play-container .project-title {
  font-size: 20px;
}

@media only screen and (max-width: 768px){
  .play-container{
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  
  }
  
}


/* art page styling */
.gallery {
  padding: 0px 70px;
}


/* about styling */
.about-container{
  /* padding: 0px 70px; */
  padding: 5%;
  margin: auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

}

.about-bio{
  font-size: 15px;
  max-width: 600px;
  /* margin-left: 50px; */
}

.about-container img{
  height: 400px;
  width: auto;
}

.about-container a{
  text-decoration: none;
  font-weight: bold;
  color:#1A51DE;
}

@media only screen and (max-width: 768px){
  .about-container img{
    width: 100%;
    height: auto;
    margin-bottom: 1.25em;
  }

  .about-bio{
    margin-left: 0px;
  }
}

/* footer styling */

.footer-container {
  margin-top: 80px;
  padding: 30px 5%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid black;
  align-items: center;

}

.copyright{
  width: 50%;
}

.socmed a{
  color: black;
  padding-left: 10px;
}

.socmed a:hover{
  color: #1A51DE;
  padding-left: 10px;
}